<template lang="pug">
  .cbr-updates-item
    h4.pb-2 {{ label }}

    base-table(:headers="headers")
      template(#tbody)
        tr(v-for="(slotItem, name, index) in item" :key="index").grouped-row
          td.grouped-col
            div
              h5.group-text {{ name }}
              totalItem(:items="slotItem")
          td.grouped-col
            table(cellspacing="0").child-table
              tr(v-for="(slotChild, index) in slotItem" :key="index").child-row
                td.child-col
                  childItem(:item="slotChild" time)
          td.grouped-col
            table(cellspacing="0").child-table
              tr(v-for="(slotChild, index) in slotItem" :key="index").child-row
                td.child-col
                  childItem(:item="slotChild" available)
          td.grouped-col
            table(cellspacing="0").child-table
              tr(v-for="(slotChild, index) in slotItem" :key="index").child-row
                td.child-col
                  childItem(:item="slotChild" latest)
</template>

<script>
export default {
  name: 'CbrUpdatesItem',

  props: {
    item: Object,
    label: String
  },

  data: () => ({
    headers: ['Location & Date', 'Time', 'Available slots', 'New slots']
  }),

  components: {
    baseTable: () => import('@/components/global/table/BaseTable.vue'),
    childItem: () => import('./CbrUpdatesChildItem.vue'),
    totalItem: () => import('./CbrUpdatesTotalItem.vue')
  }
}
</script>

<style lang="scss" scoped>
.cbr-updates-item {
  display: flex;
  flex-direction: column;
  background: #FEFFFF;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: 10px 0 10px 10px;
  padding: 15px;
  max-width: 825px;

  .grouped-row {
    &:nth-child(2n) {
      background: #F4F7F9;
    }

    .grouped-col {
      width: 120px;
      max-width: 120px;
      border-bottom: 1px solid rgba($log-primary, 0.5);

      &:first-child {
        padding: 10px 0 5px 10px;
        vertical-align: top;
        width: initial;
        max-width: initial;
      }
    }

    &:last-child {
      .grouped-col {
        border-bottom: none;
      }
    }
  }

  .child-table {
    width: 100%;
    .child-row {

      .child-col {
        padding: 4px;
        border-bottom: 1px solid $border-color;
      }

      &:last-child {
        .child-col {
          border-bottom: none;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.cbr-updates-item {
  .group-text {
    color: #272C32;
    font-weight: normal;
  }
}
</style>