<template lang="pug">
  .cbr-updates-list(
    v-infinite-scroll="onLoadMore"
    infinite-scroll-disabled="disabledLoadingMore"
    infinite-scroll-distance="400"
  )
    cbr-updates-item(v-for="(item, index) in list" :item="item" :key="index" :label="index")
    .progress-bar(v-if="loading")
      v-progress-circular(indeterminate size="32" color="warning")
</template>

<script>
import infinityScroll from '@/components/global/infinityScroll.vue'
import CbrUpdatesItem from './CbrUpdatesItem.vue'

export default {
  name: 'CbrUpdatesList',

  props: {
    list: Object,
    pagination: Object,
    loading: Boolean
  },

  computed: {
    disabledLoadingMore() {
      if (!this.pagination) return true
      if (this.loading) return true
      if (!this.pagination.next) return true
      return false
    },
  },

  methods: {
    onLoadMore() {
      this.$emit('loadMore')
    }
  },

  components: {
    infinityScroll,
    CbrUpdatesItem
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px 30px 15px;
}
</style>